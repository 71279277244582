import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import Nav from '../components/Nav'

const BlogPage =({ data, pageContext }) => {

  let ratio = data.nodeArticle.relationships.field_artikel_image.relationships.field_media_image.localFile.childImageSharp.fluid.aspectRatio
  const fullwidthImage = ratio > 1 ? (
    <div className="img-wrap">
      <Img
        fluid={data.nodeArticle.relationships.field_artikel_image.relationships.field_media_image.localFile.childImageSharp.fluid}
        className={'img'}
      />
    </div>
  ) : (
    ''
  )
  const fluidImage = ratio <= 1 ? (
    <div className="img-wrap float-left">
      <Img
        fluid={data.nodeArticle.relationships.field_artikel_image.relationships.field_media_image.localFile.childImageSharp.fluid}
        className={'img'}
      />
    </div>
  ) : (
    ''
  )
  const { prev, next } = pageContext

  return (
	<Layout>
		<Helmet title={[`Crabber-Blog`, data.nodeArticle.title].join(' - ')} />
		<HeaderGeneric title="Blog" subtitle="Crabbers Welt" />
		<Nav active="blog" />
		<div id="main">
			<article className="main">

				<header className="major">
					<h1>{data.nodeArticle.title}</h1>
					<i>
						<p className="publication-date">
							{ data.nodeArticle.created }
						</p>
					</i>
				</header>

        {fullwidthImage}

        {fluidImage}

				<div
					className="content"
        	dangerouslySetInnerHTML={{ __html: data.nodeArticle.body.processed }}
      	></div>
      	<footer className="major">
      		<ul className="actions blog-nav">
            <li className="blog-nav-item prev">
              {prev &&
                <Link to={prev.node.fields.slug} rel="prev">
                  <i
                    className="icon fa-long-arrow-left"
                    style={{ marginRight:`0.5em`, color:`#ffa728` }}
                  ></i>
                  {prev.node.title}
                </Link>
              }
            </li>
            <li className="blog-nav-item next">
              {next &&
                <Link to={next.node.fields.slug} rel="next">
                  {next.node.title}
                  <i
                    className="icon fa-long-arrow-right"
                    style={{ marginLeft:`0.5em`, color:`#ffa728` }}
                  ></i>
                </Link>
              }
            </li>
       		</ul>
    		</footer>
			</article>
		</div>
	</Layout>
  )
}

export default BlogPage

export const query = graphql`
	query($slug: String!) {
		nodeArticle(fields: { slug: { eq: $slug } }) {
			title
			created(locale: "de", formatString: "DD.MM.YYYY")
			body {
				processed
			}
      relationships {
        field_artikel_image {
          relationships {
            field_media_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
		}
	}
`